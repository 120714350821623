import React from "react"
import * as Icon from "react-feather"
import { graphql } from "gatsby"

import CaseDetail from "../../components/Shared/casedetails"

const seoTitle = "Cloud Approach"
const pageTitle = "Cloud Approach"
const subTitle = "Cloud"
const para ="   Enterprises can meet these challenges by leveraging the benefits of hybrid cloud and immutable infrastructure, code-driven compliance and security workflows, business-oriented networking and governance, and an overall framework that embraces change through collaboration and zero-trust principals (never trust, always verify)."
const blockquote = `"Define architectural standards,
establish best practices, and drive
the adoption of Enterprise Solutions."`

const cardItems = [
  {
    id: 1,
    heading: `Common Challenges`,
    listItems: (
      <>
        <p>
          Rapidly changing compliance and regulatory requirements, data security
          and governance, legacy systems integration and decommissioning, and
          unlocking the power of public cloud scalability and elasticity are a
          few of the common challenges faced by today’s global, regulated
          industries.
        </p>
      </>
    ),
  },
  {
    id: 2,
    heading: `Atyeti’s Approach`,
    listItems: (
      <>
        <p>
       
        </p>
        <li>
          <Icon.Square />
          <strong>Foundations:{" "}</strong>Implementation of the core tooling and controls required
          for hybrid cloud environments (security, identity, networking,
          observability, governance, and pipelines for continuous integration
          and deployment).
        </li>
        <li>
          <Icon.Square />
          <strong>Automation:{" "}</strong>Building reusable automations that leverage the Foundations
          established in Day 0 into end-to-end solutions. These automations
          enable streamlined and efficient provisioning, configuration, and
          management of hybrid cloud resources.
        </li>
        <li>
          <Icon.Square />
          <strong>Industrialization:{" "}</strong>Expanding the Automations defined in Day 1 into
          developer centric experiences, streamlining cloud migrations, and/or
          establishing factory style migration processes required for managing
          and evolving the hybrid cloud environment at scale.
        </li>
      </>
    ),
  },
  {
    id: 3,
    heading: `Migrations Are Transformative`,
    listItems: (
      <>
        <p></p>
        <li>
          <Icon.Square />
          <strong>Enterprise Architect:{" "}</strong>Provides strategic guidance, defines
          architectural standards, and collaborates with other teams to drive
          the adoption of best practices and technology solutions, ensuring the
          alignment of technology with business objectives.
        </li>
        <li>
          <Icon.Square />
          <strong>Group Architect:{" "}</strong>Facilitates communication, coordinates efforts,
          evangelizes needs, and ensures alignment with the architectural
          direction set by the enterprise, promoting collaboration and synergy
          within the group.
        </li>
        <li>
          <Icon.Square />
          <strong>App Team Lead:{" "}</strong>Direct and coordinate the app development team to
          achieve defined objectives. They provide guidance and support to team
          members, ensure efficient task assignment, monitor progress, and
          foster a positive working environment.
        </li>
        <li>
          <Icon.Square />
          <strong>App Team Member:{" "}</strong>Actively collaborate with the rest of the team to
          code, test, and deliver features according to business and
          architectural requirements, utilizing their skills and expertise to
          contribute to the development of applications.
        </li>
      </>
    ),
  },
  {
    id: 3,
    heading: `The Foundation`,
    listItems: (
      <>
        <p></p>
        <li>
          <Icon.Square />
          <strong> Governance:{" "}</strong>The nomenclature, hierarchy, operations,
          ownership of resources across enterprise, divisions, groups, teams,
          and individuals for service enablement. (Example: Terraform modules
          and Sentinel polices for <a href="/google-cloud-service">Google IAM.</a> )
        </li>
        <li>
          <Icon.Square />
          <strong>Identity:{" "}</strong>
          Dynamic authentication and authorization of people, services, and
          machines as ”need to know” best practices. (Example: integration of
          Okta, HashiCorp Vault, and GCP IAM for dynamic, privileged access
          using policy-as-code processes.)
        </li>
        <li>
          <Icon.Square />
          <strong>Security:{" "}</strong>
          Time-bound, dynamic, location-aware secrets, certificates, and
          policies that combine with network and identity for “zero-trust”
          (Example: on-demand certificates for GKE using HashiCorp Vault and
          Venafi.)
        </li>
        <li>
          <Icon.Square />
          <strong>Networking:{" "}</strong>
          Cross-cloud, cross-region, global patterns that prioritizes business
          alignment through service-based (rather than ip-based) networking
          enabling global canary, blue-green, and rolling upgrades of named
          services.
        </li>
        <li>
          <Icon.Square />
          <strong>Observability:{" "}</strong>
          Limit access to machines and services through immutable architecture
          while enforcing troubleshooting via OpenTelemetry (logging,
          monitoring, and tracing) through StackTrace, Grafana, DataDog, Splunk,
          etc.
        </li>
        <li>
          <Icon.Square />
          <strong>Pipelines:{" "}</strong>
          Establish paths of change across application, infrastructure,
          security, networking, and compliance teams and govern this change at
          scale over time using tools like Cloud Build, Harness.io, GitLab, and
          Jenkins.
        </li>
      </>
    ),
  },
]

const BlogDetails = ({ data }) => (
  <CaseDetail
    seoTitle={seoTitle}
    pageTitle={pageTitle}
    cardItems={cardItems}
    subTitle={subTitle}
    para={para}
    blockquote={blockquote}
    images={data}
  />
)
export const query = graphql`
  query CloudApproachQuery {
    casedetailsJson(slug: { eq: "cloud-approach" }) {
      images {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
    }
  }
`
export default BlogDetails
